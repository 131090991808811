.requestInfo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* object-fit: contain; */
}

.requestInfo * > {
    /* flex-grow: 1; */
    width: 100%;
}

#pageTitle {
    /* flex-grow: 1; */
    width: 100%;
    justify-content: center;
}

#upcheckForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

#urlInput {
    flex-grow: 1;
    max-width: 70%;
}

#upcheckButton {
    margin-left: 10px;
    background-color: #913291;
}